<template>
  <div>
    <b-list-group
      v-if="!loading"
      class="scroll"
      style="overflow-x:scroll !important; max-height: 400px"
    >
      <div v-if="accounts.length === 0">
        <h6 class="text-center">
          Não há contas cadastradas.
        </h6>
      </div>
      <b-list-group-item
        v-for="(account, index) in accounts" :key="index"

        class="flex-column align-items-start"
      >
        <div class="d-flex w-100 justify-content-between py-1">
          <span>Banco: <strong> {{account.bank_name}} </strong></span>
          <b-badge v-if="account.is_default" variant="success">ATIVA</b-badge>
        </div>
        <div class="d-flex w-100 justify-content-between py-1">
          <span>
            Agencia: <strong>{{account.branch}}</strong>
          </span>
        </div>
        <div class="d-flex w-100 justify-content-between py-1">
          <span>
            Conta: <strong>{{ account.number }}</strong>
          </span>
        </div>
        <div class="d-flex w-100 justify-content-between py-1">
          <span>Tipo: <strong>{{ setTypeAccount(account.account_type) }}</strong></span>
          <div>
            <b-button
              v-if="!account.is_default"
              size="sm"
              variant="primary"
              @click="updateDefaultAccount(account.id)"
            >
              Utilizar
            </b-button>
            <b-button
              v-if="!account.is_default"
              size="sm"
              variant="danger"
              class="ml-1"
              @click="deleteAccount(account.id)"
            >
              Excluir
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div
      v-else
      class="text-center text-dark my-2">
      <b-spinner class="align-middle" />
      <strong>Carregando...</strong>
    </div>
  </div>
</template>

<script>
import GET_BANK_ACCOUNTS from '@graphql/payment/queries/getBankAccounts.gql';
import { BANK_ACCOUNT_TYPE_LIST } from '@enums/bankAccountType';
import { makeQuery } from '@graphql/middleware'
export default {
  name: 'ListBankAccount',
  props: {
    driverId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      accounts: [],
      loading: true,
      bankAcountType: BANK_ACCOUNT_TYPE_LIST
    }
  },
  mounted () {
    this.getBankAccounts()
  },
  methods: {
    getBankAccounts () {
      this.load = true
      makeQuery(
        GET_BANK_ACCOUNTS,
        { 
          driver: this.driverId
        }
      )
      .then((res) => {
        this.accounts = res.data.getBankAccounts
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
    },
    setTypeAccount(type) {
      const accountType = this.bankAcountType.find((account) => account.value === type)
      return accountType.text
    },
    updateDefaultAccount (accountId) {
      this.$emit('updateDefault', accountId )
    },
    deleteAccount (accountId) {
      this.$emit('delete', accountId )
    }
  }
}
</script>